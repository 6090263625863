import React  from "react";
import { useState, useEffect } from "react";

import chatbot_img from "../../images/chatbot.png";

function Loading2(props) {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setTimeout(()=>{setLoading(false)}, 1000)
    }, [])

    return(
        <div>
            {loading ?
                <div className="flex gap-5 items-center">
                    <img className="w-12 h-12" src={chatbot_img} alt="chatbot" />
                    <div class="bg-white rounded-md p-3 flex gap-2 shadow-xl border border-gray-100">
                        <div class="w-4 h-4 rounded-full animate-pulse bg-blue-500"></div>
                        <div class="w-4 h-4 rounded-full animate-pulse bg-blue-600"></div>
                        <div class="w-4 h-4 rounded-full animate-pulse bg-blue-700"></div>
                    </div>
                </div>
            :
                <div>
                    {props.children}
                </div>
            }
        </div> 
    )
    
}

export default Loading2;


