import React  from "react";
import { useState } from "react";
import Helmet from 'react-helmet';
import Layout from '../components/layout';
import favicon from '../images/favicon.ico';

import Loading1 from '../components/loading/loading1';
import Loading2 from '../components/loading/loading2';

import en_logo from "../images/en.svg";
import sv_logo from "../images/sv.svg";

// images
import teamviewer_icon from "../images/TeamViewer_Logo.png";
import chatbot_img from "../images/chatbot.png";

function Support() {
    const [englishForm, setEnglishForm] = useState(false);

    return(
        <Layout>
            <Helmet>
                <title>Support | Crediflow AB</title>
                <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
                <meta name="description" content="Crediflows supportavdelning är alltid redo för att hjälpa dig  med Leverantörsfaktura, Kundfaktura, CrossState eVoyce, Feltolkning, Spåra faktura, edi och övrigt" />
                <meta name="keywords" content="Leverantörsfaktura | Kundfaktura | CrossState eVoyce" />
                <meta property="og:title" content="Crediflow AB" />
                <meta property="og:tyope" content="website" />
                <meta property="og:discription" content="Crediflows supportavdelning är alltid redo för att hjälpa dig!" />
                <meta property="og:image" content="" />
                <meta property="og:locale" content="sv_SE" />
                <meta property="og:url" content="http://www.crediflow.se/support" />
                <link rel="cannonical" href="http://www.crediflow.se/support" />
            </Helmet>

            <section className="border-t border-gray-100 pt-28">
                <div className="container mx-auto px-4 xl:px-24">
                    <div className="grid lg:grid-cols-12 gap-4">
                        <div className="col-span-9">
                            <h1 className="title-font font-semibold text-3xl text-blue-custome">Välkommen till vår support</h1>
                            <p className="mt-8 leading-relaxed font-bold text-lg text-gray-600">Öppettider</p>
                            <p className="leading-relaxed text-lg text-gray-600">
                                Support: mån &#8211; fre, 08.00 &#8211; 17.00 med lunchstängt 12.00-13.00.<br/>
                                Dag före röd dag stänger supporten 14.00, röda dagar är supporten stängd.
                            </p>
                        </div>
                        <div className="hidden col-span-3 lg:flex justify-end" >
                            <a  className="h-16 w-44 flex items-center border border-gray-200 rounded-lg shadow-md hover:shadow-lg p-2"  href="https://get.teamviewer.com/osv12" target="_blank" rel="noreferrer">
                                <img className="w-12 h-12" src={teamviewer_icon} alt="supportavdelning" />
                                <p className="px-2 text-center text-gray-600">Ladda ner Team Viewer</p>
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            
            <section className="pt-20 pb-12">
                <div className="container mx-auto px-4 xl:px-24">
                    <div className="w-full lg:bg-support-girl bg-no-repeat bg-auto bg-right-top" style={{"minHeight":"800px", "backgroundSize": "45%"}}>
                        <div className="w-full lg:w-9/12 xl:w-8/12 2xl:w-7/12 ">
                            {!englishForm ?
                                <Loading1>
                                        <div className="flex gap-5 items-start">
                                            <img className="w-12 h-12" src={chatbot_img} alt="chatbot" />
                                            <div className="transition ease-in duration-200 rounded-md">
                                                <p className="bg-white border border-gray-100 p-2 shadow-xl rounded-md">Välkommen till Crediflows egna supportavdelning som alltid är redo för att hjälpa dig och dina anställda 👋 Vad kan vi hjälpa dig med?</p>    
                                            </div>
                                        </div>
                                        
                                        
                                        <div className="mt-6 w-full flex justify-end items-end">
                                            <button onClick={()=> {setEnglishForm(true)}} className="flex items-center gap-2 bg-blue-custome text-white py-2 px-4 rounded-md shadow-xl hover:opacity-90">
                                                <img src={en_logo} alt="SV" className="h-4 w-4"/> 
                                                In English
                                            </button>
                                        </div>

                                        <div className="mt-6 w-full rounded-lg overflow-hidden" style={{"height": "900px"}}>
                                            <iframe title="crediflow-support" className="w-full h-full" src="https://online4.superoffice.com/Cust17637/CS/scripts/customer.fcgi?action=formFrame&formId=1" />
                                        </div>
                                </Loading1>
                            :
                                <Loading2>
                                    <div className="flex gap-5 items-start">
                                        <img className="w-12 h-12" src={chatbot_img} alt="chatbot" />
                                        <div className="transition ease-in duration-200 rounded-md">
                                            <p className="bg-white border border-gray-100 p-2 shadow-xl rounded-md">Welcome to Crediflows's own support department. We are always here ready to help you and your employees. What can we assist you with today? 😊</p>    
                                        </div>
                                    </div>

                                    <div className="mt-6 w-full flex justify-end items-end">
                                        <button onClick={()=> {setEnglishForm(false)}} className="flex items-center gap-2 bg-blue-custome text-white py-2 px-4 rounded-md shadow-xl hover:opacity-90">
                                            <img src={sv_logo} alt="SV" className="h-4 w-4"/> 
                                            På Svenska
                                        </button>
                                    </div>

                                    <div className="mt-6 w-full rounded-lg overflow-hidden" style={{"height": "900px"}}>
                                        <iframe title="crediflow-support" className="w-full h-full" src="https://online4.superoffice.com/Cust17637/CS/scripts/customer.fcgi?action=formFrame&formId=F-chzfergX" />
                                    </div>
                                </Loading2>
                            }
                        </div>
                        


                        <div className="lg:hidden w-full h-full flex items-end justify-end">
                            <div className="min-h-36 py-40 lg:py-96 w-9/12 lg:w-5/12 bg-support-girl bg-no-repeat bg-contain bg-right-top">
                            </div>
                        </div>
                    </div>
                     
                </div>
            </section>
        </Layout>
    );
}
export default Support;